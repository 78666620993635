<template>
  <OField :label="label" :title="value">
    <ODropdown ref="Dropdown" @active-change="onActiveChange">
      <button
        slot="trigger"
        slot-scope="{ active }"
        :class="[
          'ucsc-input',
          {
            'ucsc-input--placeholder': !value,
            'ucsc-input--focused': active,
          },
        ]"
        type="button"
      >
        <span class="ucsc-input__inner"> {{ value || placeholder }} </span>
        <SvgIcon
          :name="active ? 'angle-up-blue' : 'angle-down'"
          class="ml-2 o-drop__trigger-icon"
        />
      </button>
      <ClientOnly>
        <div class="flex flex-col py-10 px-6">
          <slot></slot>
        </div>
      </ClientOnly>
    </ODropdown>
  </OField>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },
  },

  methods: {
    close() {
      // This hack is required due to the lack of APIs offered by Oruga's Dropdowns.
      this.$refs.Dropdown.isActive = false
    },

    onActiveChange(payload) {
      if (payload) {
        return
      }

      this.$emit('close')
    },
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/components/dropdown';
@import '~/assets/css/components/field';
</style>
