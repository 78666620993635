<template>
  <VisualHero
    :class="[
      'md:order-none',
      {
        'order-last': heroOrderLast,
      },
    ]"
    :img="heroImg"
    :mobile-img="heroImgMobile"
    :gradient="heroGradient"
    :size="heroSize"
    inner-justify="start"
    img-x-position="5-listing"
    img-y-position="center"
    preload
    @description-collapse="$emit('description-collapse', $event)"
  >
    <slot />
    <slot slot="description" name="description"></slot>
  </VisualHero>
</template>

<script>
// Static assets
import { Sections } from '~/assets/js/sections'

// Components
import VisualHero from '~/components/VisualHero'

export default {
  components: {
    VisualHero,
  },
  props: {
    section: {
      type: String,
      required: true,
    },

    heroSize: {
      type: String,
      default: 'medium',
    },

    heroOrderLast: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      heroImg: null,
      heroImgMobile: null,
      heroGradient: true,
      sectionStyleMap: null,
    }
  },

  watch: {
    section: {
      immediate: true,
      handler(section, prevSection) {
        const computedSection = !section && prevSection ? prevSection : section

        if (!this.sectionStyleMap) {
          this.sectionStyleMap = {
            [Sections.NuoveCostruzioni]: {
              heroImg: this.$img(
                '/assets/img/visual/ucsc-visual-nuovecostruzioni.png'
              ),
              heroImgMobile: this.$img(
                '/assets/img/visual/ucsc-visual-nuovecostruzioni-mobile.png'
              ),
              heroGradient: false,
            },
            [Sections.ImmobiliCommerciali]: {
              heroImg: this.$img(
                '/assets/img/visual/ucsc-visual-immobilicommerciali.png'
              ),
              heroImgMobile: this.$img(
                '/assets/img/visual/ucsc-visual-immobilicommerciali-mobile.png'
              ),
              heroGradient: false,
            },
            [Sections.ImmobiliDiPregio]: {
              heroImg: this.$img(
                '/assets/img/visual/ucsc-visual-immobilidipregio.png'
              ),
              heroImgMobile: this.$img(
                '/assets/img/visual/ucsc-visual-immobilidipregio-mobile.png'
              ),
              heroGradient: false,
            },
            [Sections.ProprietaUnicredit]: {
              heroImg: this.$img(
                '/assets/img/visual/ucsc-visual-proprieta-unicredit.png'
              ),
              heroImgMobile: this.$img(
                '/assets/img/visual/ucsc-visual-proprieta-unicredit-mobile.png'
              ),
              heroGradient: false,
            },
            [Sections.VenditaImmobili]: {
              heroImg: this.$img(
                '/assets/img/visual/ucsc-visual-venditaimmobili.png'
              ),
              heroImgMobile: this.$img(
                '/assets/img/visual/ucsc-visual-venditaimmobili-mobile.png'
              ),
              heroGradient: false,
            },
            default: {
              heroImg: this.$img(
                '/assets/img/visual/ucsc-visual-venditaimmobili.png'
              ),
              heroImgMobile: this.$img(
                '/assets/img/visual/ucsc-visual-venditaimmobili-mobile.png'
              ),
              heroGradient: false,
            },
          }
        }

        const { heroImg, heroImgMobile, heroGradient } =
          this.sectionStyleMap[computedSection] ?? this.sectionStyleMap.default

        this.heroImg = heroImg
        this.heroImgMobile = heroImgMobile
        this.heroGradient = heroGradient
      },
    },
  },
}
</script>
