<template>
  <div class="ucsc-modal__inner">
    <div class="ucsc-modal__header">
      <div class="ucsc-modal__logoWrapper">
        <Logo :type="$options.LogoTypes.NoPayoff" />
      </div>
      <button
        class="ucsc-modal__close"
        aria-label="Chiudi modale"
        @click="close"
        @keyup.enter="close"
      ></button>
    </div>
    <div
      :class="[
        'ucsc-modal__content',
        {
          'ucsc-modal__content--white': bgColor === 'white',
          'ucsc-modal__content--p-medium': padding === 'medium',
        },
      ]"
    >
      <div class="ucsc-modal__component-inner" :class="contentClass">
        <component
          :is="component"
          v-if="component"
          v-bind="componentProps"
          v-on="componentEvents"
          @close="close"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Static assets
import LogoTypes from '~/assets/js/logo/types'

// Components
import Logo from '~/components/Logo.vue'

export default {
  components: {
    Logo,
  },
  props: {
    bgColor: {
      type: String,
      default: 'white',
    },
    padding: {
      type: String,
      default: 'medium',
    },
    contentClass: {
      type: String,
      default: '',
    },
    component: {
      type: [Object, Function],
      default: () => null,
    },
    componentProps: {
      type: Object,
      default: () => null,
    },
    componentEvents: {
      type: Object,
      default: () => null,
    },
  },

  LogoTypes,

  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
