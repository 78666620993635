<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.name"
    :slim="true"
    :rules="rules"
  >
    <OField
      :label="label || $attrs.placeholder"
      :label-for="uid"
      :label-class="!label && $attrs.placeholder ? 'sr-only' : ''"
      :variant="errors && errors[$attrs.name] ? 'danger' : ''"
      :message="errors && errors[$attrs.name] && errors[$attrs.name][0]"
      :root-class="fieldClasses"
      :data-name="$attrs.name"
    >
      <div class="relative">
        <OInput
          :id="uid"
          ref="OInput"
          :value="value"
          :readonly="$attrs.disabled"
          :aria-disabled="'disabled' in $attrs"
          :required="`${rules.includes('required')}`"
          :placeholder="$attrs.placeholder"
          :type="$attrs.type"
          :name="$attrs.name"
          :class="classes"
          :input-class="computedInputClass"
          autocomplete="on"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          @input="$emit('input', $event)"
        >
        </OInput>
        <SvgIcon
          v-if="passwordReveal"
          class="w-9.6 min-w-9.6 h-9.6 absolute bottom-3 right-3 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue focus-visible:rounded-full"
          tabindex="0"
          role="button"
          :name="showPassword ? 'hide' : 'show'"
          :aria-label="showPassword ? 'Nascondi password' : 'Mostra password'"
          @keyup.enter="toggleShowPassword"
          @click="toggleShowPassword"
        />
      </div>
    </OField>
  </ValidationProvider>
</template>

<script>
import InputDropdownErrorsMixin from '~/mixins/input-dropdown-errors'
import UniqueIdMixin from '~/mixins/unique-id'

export default {
  mixins: [InputDropdownErrorsMixin, UniqueIdMixin],

  props: {
    vid: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    passwordReveal: {
      type: Boolean,
      default: false,
    },

    fieldClasses: {
      type: String,
      default: '',
    },

    classes: {
      type: [String, Object, Array],
      default: null,
    },

    inputClasses: {
      type: [Array, Boolean],
      default: null,
    },

    value: {
      type: null,
      default: null,
    },

    rules: {
      type: [Object, String],
      default: '',
    },

    errors: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showPassword: false,
    }
  },

  computed: {
    computedInputClass() {
      const { errors, inputClasses } = this

      const computedInputClassdas = [
        'ucsc-input--focus',
        ...(inputClasses || []),
      ]

      if (!errors) {
        return computedInputClassdas
      }

      const { name } = this.$attrs

      if (errors[name]?.[0]) {
        computedInputClassdas.push('ucsc-input--danger')
      }

      return computedInputClassdas
    },

    inputElement() {
      const { OInput } = this.$refs
      const inputElement = OInput.$refs.input

      return inputElement
    },
  },
  methods: {
    toggleShowPassword() {
      const { OInput } = this.$refs
      const inputElement = OInput.$refs.input

      this.showPassword = !this.showPassword

      // this.$attrs.type should be password when inside this function
      inputElement.type = this.showPassword ? 'text' : this.$attrs.type
    },
  },
}
</script>
